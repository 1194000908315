#header {
  position: relative;
}
.header {
  display: grid;
  /* justify-content: center;
  align-items: flex-start; */
  grid-template-columns: 50% 50%;
}

.header h1 {
  font-size: 3rem;
  line-height: 1.4;
  text-transform: uppercase;
  color:  var(--dark-blue);
  position: relative;
  padding-bottom: 2rem;
}

.header h1 span {
  display: block;
}

h1 span:nth-child(1) {
  font-weight: 700;
}

h1 span:nth-child(2),
h1 span:nth-child(3),
h1 span:nth-child(4) {
  font-weight: 300;
  font-size:2.4rem;
}

.header h1::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 65%;
  height: 1px;
  background: var(--dark-blue);;
}

.header-left p {
  margin: 3rem 0;
  color:var(--dark-blue);;
  width: 70%;
}
.header-left {
padding: 1rem;
}
.header-right  {
  width: 100%;
  height: 100vh;
  border-left: 1px solid var(--dark-blue);;
}
.header-right img{
  width: 100%;
  height: 80%;
  border-radius: 1rem;
  margin: 1rem;
}

.floating-icon {
  background-color: var(--light-blue);
  width: 5rem;
  height: 5rem;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);
  cursor: pointer;
}

.floating-icon a {
  border: 1px solid #fff;
  width: 5rem;
  height: 5rem;
  border-radius: 50%;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
}

.mouse {
  transform: translateY(-25%);
  animation: bounce 0.8s ease infinite alternate;
}

@keyframes bounce {
  0% {
    transform: translateY(-25%);
  }
  100% {
    transform: translateY(25%);
  }
}

@media screen and (max-width: 769px) {
  .header h1 {
    font-size: 3rem;
   }
}

@media screen and (max-width: 600px) {
  .header {
    display: flex;
    flex-direction: column;
  }

  .header-left,
  .header-right {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 0 auto;
  }

  .header-left {
    margin-bottom: 4rem;
  }

  .header h1::after {
    left: 50%;
    transform: translateX(-50%);
  }
  .header-right img {
   
    height: 80%;
  }
  .header-right  {
    height: 70vh;
    border: none;
  }
}
