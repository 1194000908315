section#footer {
  background:#041a36;
  color: #fff;
}

.footer {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
  row-gap: 2rem;
  column-gap: 2rem;
}

/* .footer > * {
  flex: 1;
  justify-self: center;
} */

.footer h4 {
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 1.4;
  text-transform: uppercase;
  margin-bottom: 1rem;
}

.footer-box .footer-links a {
  font-size: 1.2rem;
  margin: 5px;
}
.footer-box img{
  width: 100%;
  /* height: 200px; */
}

.footer-links {
  display: flex;
  flex-direction: column;
}

.footer-contact p {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}
.footer-text{
  text-align: center;
}

@media screen and (max-width:768px) {
  .footer-box img{
    width: 100%;
    height: 300px;
  }
  .footer-text{
    text-align:left;
    margin: 1rem;
  }
}