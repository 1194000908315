section#subscribe {
  background: url(../../assets/subscibeimage.jpg);
  text-align: center;
  color: #fff;
  background-size: cover;
  background-attachment: fixed;
}

.subscribe h2 {
  font-size: 3rem;
  font-weight: 200;
  line-height: 1.4;
  text-transform: uppercase;
  margin: 1.5rem 0;
  padding-bottom: 1rem;
}

form > * {
  width: 100%;
  max-width: 50rem;
  margin: 3rem auto;
}

form input {
  padding: 1rem;
  background: #96d3fb96;
  border: 1.5px solid #0256ff;
  border-radius: 5px 0 0 5px;
  color: #3b7fff;
  outline: none;
  width: 80%;
  font-size: 1.6rem;
}
form input::placeholder{
  color: rgb(31, 31, 31);
}
form button {
  padding: 0;
  background: rgb(2, 2, 2);
  border: 1px solid rgb(191, 176, 167);
  border-radius: 0 5px 5px 0;
  color: #fff;
  font-size: 1.6rem;
  font-weight: 500;
  cursor: pointer;
  height: 4rem;

  
}
form button:hover{
  background:#0024ff ;
  
}



.social-icons {
  display: flex;
  justify-content: center;
  align-items: center;
}

.social-icon {
  margin: 2rem;
  padding: 1rem;
  width: 4rem;
  height: 4rem;
  border: 1px solid #000;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  cursor: pointer;
  transition: all 0.3s;
}

.social-icon:hover {
  background: #007aff;
}

@media screen and (max-width: 600px) {
  form input {
    width: 60%;
  }
}
