.awards {
    background-color: #041a36;
    color: #fff;
    text-align: center;
    display: flex;
    justify-content: center;
   
  }
  .awards .heading {
    display: flex;
    flex-direction: column-reverse;
  }
  .awards .heading p {
    color: #08f56b;
  }
  .awards .heading h1 {
    color: #ffffffcf;
    font-size: 10px;
    font-weight: 200;
  }
  .awards .icon {
    width: 100px;
    height: 100px;
    line-height: 100px;
    margin: auto;
    margin-top: 50px;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 100%;
    font-size: 40px;
    color: #ffffff;
    border: 1px solid #fff;
  }
  .icon :hover{
    background:rgba(108, 75, 75, 0.1) ;
  }
  .awards h1 {
    font-size: 30px;
  }
  .box h1{
    font-size: 25px;
  }
  .awards p {
    color: grey;
    font-size:15px ;
  }
  .grid4{
    display: grid;
    grid-template-columns: repeat(4,1fr);
    gap: 2rem;
  }
  .grid4 h1{
    color: #ffffffbc;
    font-size: 4rem;
    font-weight: 300;
  }
  
@media (max-width: 768px) {
  .grid4 {
    grid-template-columns: repeat(2, 1fr);
  }

  .grid4 h1 {
    font-size: 3rem;
  }
}

@media (max-width: 576px) {
  .grid4 {
    grid-template-columns: repeat(1, 1fr);
  }

  .grid4 h1 {
    font-size: 2rem;
  }
}