.contact_container {
    width: 58%;
    display: grid;
    grid-template-columns: 32% 58%;
    gap: 12%;
    border-radius: 1rem;
    /* border: 1px solid var(--dark-blue); */
    background-color: rgba(4, 41, 74, 0.336);
    padding: 1rem;
    padding-bottom: 1rem;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.388); /* Adjust values as needed */
}

#container{
background: url('https://cdn.pixabay.com/photo/2022/12/19/06/57/stethoscope-7664838_1280.jpg');
background-size: cover;

}

.contact_options {
    display: flex;
    flex-direction: column;
    gap: 3rem;

}

.contact_option {
    background: var(--color-bg-variant);
    padding: 1.2rem;
    border-radius: 1.2rem;
    text-align: center;
    border: 1px solid transparent;
    transition: var(--transition);
}
.contact_option:hover{
    background: transparent;
    border-color: var(--color-primary-variant);
}

.contact_option-icon {
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
}

.contact_option a{
    margin-top: 0.7rem;
    display: inline-block;
    font-size: 0.9rem ;
}
.contact_option  h5{
    color: var(--dark-blue);

}
/* 
form */

form {
    display: flex;
    flex-direction: column;
    gap: 1.2rem;

}

input, textarea {
    width: 100%;
    padding: 1rem;
    border-radius: 0.5rem;
    background: transparent;
    border: 2px solid var(--color-primary-variant);
    resize: none;
    color: #fff;
}
textarea {
    background:rgba(255, 255, 255, 0.433) ;
margin: 1rem;
color: #fff;
}
.contact{
    margin: 1rem;
}

/*medium devices*/
@media screen and (max-width: 1024px){
    .container.contact_container{
        grid-template-columns: 1fr;
        gap: 2rem;
    }
  }
  
  
      /*small devices*/
  @media screen and (max-width: 600px){
    
    .container.contact_container{
        width: var(--container-width-sm);
    }
      
  
  }